<template>
  <v-row align="center" justify="center" class="text-center" no-gutters>
    <v-card width="90%" class="pa-10">
      <v-row align="center" justify="center" class="text-center" no-gutters>
        <v-img
          src="@/assets/logo_survey.png"
          max-width="180px"
          max-height="180px"
        />
      </v-row>
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <p style="font-size: 18px" class="headtitle">บันทึกข้อมูลคณะกรรมการคุ้ม</p>
        <!-- <p>ชุมชน {{ user.station }}</p>
        <p>สภ./สน {{ user.station }}</p>
        <p>ภ.จว./บก.น.{{ user.area }}</p> -->
        <!-- <pre>{{questionName}}</pre> -->
        <v-row>
          <v-col cols="12" style="text-align: left"
            ><h3>สถานี/ชุมชน</h3></v-col
          >
          <v-col cols="12" md="8" sm="12" xs="12">
            <v-autocomplete
              label="สถานี/ชุมชน"
              v-model="user.station"
              :items="items"
              item-text="searchText"
              item-value="station"
              :rules="rules.required"
              :disabled="true"
            ></v-autocomplete>
          </v-col>
          <!-- nameKum: "",
        fullName: "",
        age: "",
        tel: "",
        position: "", -->
          <v-col cols="12" style="text-align: left"
            ><h3>ข้อมูลคณะกรรมการคุ้ม</h3></v-col
          >
          <!-- <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field
              v-model="user.nameKum"
              label="ชื่อคุ้ม"
              :rules="rules.required"
            ></v-text-field>
          </v-col> -->
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field
              v-model="user.gangName"
              label="ชื่อคุ้ม"
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field
              v-model="user.name"
              label="ชื่อ-สกุล"
              :rules="rules.required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field
              v-model="user.phone"
              label="เบอร์โทรศัพท์"
              :rules="rules.phone"
              type="number"
              counter="10"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-text-field
              v-model="user.age"
              label="อายุ"
              :rules="rules.age"
              type="number"
              :maxlength="maxLengthAge"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6" xs="12">
            <v-select
              v-model="user.position"
              :items="positionItems"
              label="ตำแหน่งคุ้ม"
              :rules="rules.required"
            ></v-select>
          </v-col>
        </v-row>

        <v-btn class="mt-4" @click="cancel()">ย้อนกลับ</v-btn>
        <v-btn color="#833133" dark @click="submit()" class="mx-4 mt-4"
          >บันทึก</v-btn
        >
      </v-form>
    </v-card>
  </v-row>
</template>

<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      checkValidate: "", //ห้ามลบ
      lazy: "", //ห้ามลบ
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน"],
        password: [(v) => !!v || "Password is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        age: [
          (v) => v.length == 2 || "อายุต้องอยู่ระหว่าง 12-99 ปี เท่านั้น",
          (v) => v >= 12 || "อายุต้องอยู่ระหว่าง 12-99 ปี เท่านั้น",
        ],
        phone: [
          (v) =>
            (v.length <= 10 && v.length >= 9) ||
            "หมายเลขโทรศัพท์ต้องมี 9-10 ตัวเท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน",
        ],
      },
      maxLengthAge: 2,

      village: "",
      station: "",
      district: "",
      subDistrict: "",
      province: "",
      dropdown: "",
      group: "",
      area: [],
      items: [],
      items2: [],
      items3: [],
      items4: [],
      selected: false,
      user: {
        userId: "",
        gangName: "",
        name: "",
        age: "",
        phone: "",
        position: "",
        station: "",
      },
      positionItems: [
        { text: "ประธานคุ้ม", value: "ประธานคุ้ม" },
        { text: "กรรมการคุ้ม", value: "กรรมการคุ้ม" },
      ],
    };
  },
  created() {
    this.searchDropdown();
    var checkuser = JSON.parse(Decode.decode(localStorage.getItem("user")));
    this.user.userId = checkuser.id;
    this.user.station = checkuser.station;
  },
  methods: {
    async searchDropdown() {
      // const response = await this.axios.get(
      //   `https://policesurveyservice.yuzudigital.com/form/getAllStation`
      // );
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);
      this.items.forEach((item) => {
        item.searchText = item.station + " " + item.village;
      });
    },
    cancel() {
      this.$router.push("manageKum");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        console.log("this.user", this.user);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/gangs/create`,
          this.user
        );
        console.log("response", response);
        if (response.data.response_status !== "ERROR") {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "success",
            text: "บันทึกข้อมูลสำเร็จ",
          });
          //   this.$router.push("ManageUser");
          location.reload();
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "เบอร์นี้ถูกใช้แล้ว",
          });
        }
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณากรอกข้อมูล",
        });
      }
    },
    resetValidation() {
      this.$refs.createForm.resetValidation();
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
